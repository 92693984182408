import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT Server with Mosquitto",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/Mosquitto/",
  "dateChanged": "2019-03-26",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Mosquitto"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='INSTAR MQTT Server with Mosquitto' dateChanged='2019-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/Mosquitto/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/Mosquitto/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-mqtt-server-with-mosquitto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-server-with-mosquitto",
        "aria-label": "instar mqtt server with mosquitto permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Server with Mosquitto`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#docker-installation"
        }}>{`Docker Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#setting-up-mosquitto"
        }}>{`Setting up Mosquitto`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#running-the-broker"
        }}>{`Running the Broker`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#user-authentication"
            }}>{`User Authentication`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-cameras"
        }}>{`Connecting Cameras`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red"
        }}>{`Node-RED`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`We are going to use the Open Source `}<a parentName="p" {...{
        "href": "http://mosquitto.org"
      }}>{`Mosquitto MQTT broker`}</a>{` to control our INSTAR Full HD IP cameras. We will install it via `}<a parentName="p" {...{
        "href": "https://hub.docker.com/_/eclipse-mosquitto"
      }}>{`Docker`}</a>{` and run the broker on a CentOS LINUX server.`}</p>
    <h2 {...{
      "id": "docker-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#docker-installation",
        "aria-label": "docker installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Docker Installation`}</h2>
    <p>{`Docker can be installed on Linux, Windows and macOS. In this example we will use CentOS Linux - for other operating systems, `}<a parentName="p" {...{
        "href": "https://docs.docker.com/install/"
      }}>{`please check the official documentation`}</a>{`.`}</p>
    <p>{`First we need to add the repository to pull the Docker code from - type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nano /etc/yum.repos.d/virt7-docker-common-release.repo`}</code>{` and add:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`virt7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`docker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`common`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`release`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
name=virt7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`docker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`common`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`release
baseurl=http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//cbs.centos.org/repos/virt7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`docker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`common`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`release/x86_64/os/
gpgcheck=0`}</code></pre></div>
    <p>{`Then install Docker on Centos server:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yum update
yum -y `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` --enablerepo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`virt7-docker-common-release docker
systemctl `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`enable`}</span>{` docker
systemctl start docker`}</code></pre></div>
    <h2 {...{
      "id": "setting-up-mosquitto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-mosquitto",
        "aria-label": "setting up mosquitto permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up Mosquitto`}</h2>
    <p>{`Since all the data inside the Docker container, that runs our broker, will be erased once the container is stopped, we want to create three directories outside of the container to store data we want to persist - the broker configuration, persistent storage and logs:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/mosquitto/
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/mosquitto/config
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/mosquitto/data
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/mosquitto/log`}</code></pre></div>
    <p>{`Now create a configuration file that your broker should use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo nano /opt/mosquitto/config/mosquitto.conf`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`persistence true
persistence_location /opt/mosquitto/data
log_dest file /mosquitto/log/mosquitto.log`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/85c972364a27a16977d868e0628efdf9/1132d/Mosquitto_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAm0lEQVQI11XL2wqCQBgE4L3pCL2FByLTXd1D5W56V4LUGpZC7/8cE2tldPExP8P8JOISm4zDZSzVaCskuDYQxwKqKEdZrhFxgeSzo2r37oREqvYg8bVDYnvw9jlkbDvQpkd0eSCoGvhnx8I72eEOqxvWdTvs3K/z3Yb1HWSZGUxpjhnTmDONSXLAIjUj169ECRKwH9+h/1zvMbwAAQJgV4NO5WUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85c972364a27a16977d868e0628efdf9/e4706/Mosquitto_01.avif 230w", "/en/static/85c972364a27a16977d868e0628efdf9/d1af7/Mosquitto_01.avif 460w", "/en/static/85c972364a27a16977d868e0628efdf9/7f308/Mosquitto_01.avif 920w", "/en/static/85c972364a27a16977d868e0628efdf9/31e76/Mosquitto_01.avif 1158w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/85c972364a27a16977d868e0628efdf9/a0b58/Mosquitto_01.webp 230w", "/en/static/85c972364a27a16977d868e0628efdf9/bc10c/Mosquitto_01.webp 460w", "/en/static/85c972364a27a16977d868e0628efdf9/966d8/Mosquitto_01.webp 920w", "/en/static/85c972364a27a16977d868e0628efdf9/35062/Mosquitto_01.webp 1158w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85c972364a27a16977d868e0628efdf9/81c8e/Mosquitto_01.png 230w", "/en/static/85c972364a27a16977d868e0628efdf9/08a84/Mosquitto_01.png 460w", "/en/static/85c972364a27a16977d868e0628efdf9/c0255/Mosquitto_01.png 920w", "/en/static/85c972364a27a16977d868e0628efdf9/1132d/Mosquitto_01.png 1158w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/85c972364a27a16977d868e0628efdf9/c0255/Mosquitto_01.png",
              "alt": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "title": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`You can `}<a parentName="p" {...{
          "href": "/en/Node-RED_Flows/mosquitto.conf"
        }}>{`download a ready-to-use configuration template`}</a>{` from here and continue working with this one instead. It already contains all the edits that we are going to add in the following steps. `}<strong parentName="p">{`Note`}</strong>{` that you will have to choose the same directories as laid out in this tutorial for this file to work.`}</p>
    </blockquote>
    <h2 {...{
      "id": "running-the-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#running-the-broker",
        "aria-label": "running the broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running the Broker`}</h2>
    <p>{`To run the Docker image for `}<a parentName="p" {...{
        "href": "https://hub.docker.com/_/eclipse-mosquitto"
      }}>{`Eclipse Mosquitto`}</a>{` with the configuration file we just created, we have to tell docker to mount the file:`}</p>
    {/* ```bash sudo docker run -ti -p 1883:1883 -p 9001:9001 -v mosquitto.conf:/opt/mosquitto/config/mosquitto.conf -v /opt/mosquitto/data -v /opt/mosquitto/log eclipse-mosquitto ``` */}
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` docker run -ti -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1883`}</span>{`:1883 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{`:9001 -v /opt/mosquitto/config:/mosquitto/config -v /opt/mosquitto/data:/mosquitto/data -v /opt/mosquitto/log:/mosquitto/log eclipse-mosquitto`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c4bf653ce8fe4b094b8c37dec0177726/9937c/Mosquitto_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABnElEQVQoz12P63KiQBCFMcbECxgisbZ2BQOoYLjOIJcZQCPr+z/T2ZqBTdz98dU53TN1ult5DwJsPA8/9weYvo+N58M6HuFEEewwghPHst7s9vjl7GCKf/sDfrzbeDMtqYK1tYVpu1D0tIRBOVaEwci45JVUeE0rGJRhRRmW4Qmql0D10oEEmp9C88mXLo8U+geFEsUMNmlgJxxmWMKKKuld0sAlNbYxg0sb7LIWjtDTGS5tZe1mLQ75BQ5psE04nJRDsfkNFv8Nk3VYZy0MwrGmDQxS4402WJEaxqB6wqAn/I6+1qIS86CAFuRQ1KqDXt+wKK/Qqk6illfMi8+vnvCCWX7BKGYYJRxKWEL5yHuC4hu96rDiNyzLK17KK0StFZ8SnXWyp+YX+S56z6TBJOWYJByPMcN4YBRVGIshL2Kj/IJZ1mJKW8yys/T3TGmD6fA+P52lCp5IjUnaMxYDoqoPXPwXOP0n/IxnEUhbuZ0IFAHCPyYcTzKQyy0nMesDl8NZ4vNC6rdf3PXFUPXv2ckQknI8iM3CEg9hgT9nnQaOHRQ7jAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c4bf653ce8fe4b094b8c37dec0177726/e4706/Mosquitto_02.avif 230w", "/en/static/c4bf653ce8fe4b094b8c37dec0177726/d1af7/Mosquitto_02.avif 460w", "/en/static/c4bf653ce8fe4b094b8c37dec0177726/7f308/Mosquitto_02.avif 920w", "/en/static/c4bf653ce8fe4b094b8c37dec0177726/37369/Mosquitto_02.avif 1156w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c4bf653ce8fe4b094b8c37dec0177726/a0b58/Mosquitto_02.webp 230w", "/en/static/c4bf653ce8fe4b094b8c37dec0177726/bc10c/Mosquitto_02.webp 460w", "/en/static/c4bf653ce8fe4b094b8c37dec0177726/966d8/Mosquitto_02.webp 920w", "/en/static/c4bf653ce8fe4b094b8c37dec0177726/df6ba/Mosquitto_02.webp 1156w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c4bf653ce8fe4b094b8c37dec0177726/81c8e/Mosquitto_02.png 230w", "/en/static/c4bf653ce8fe4b094b8c37dec0177726/08a84/Mosquitto_02.png 460w", "/en/static/c4bf653ce8fe4b094b8c37dec0177726/c0255/Mosquitto_02.png 920w", "/en/static/c4bf653ce8fe4b094b8c37dec0177726/9937c/Mosquitto_02.png 1156w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c4bf653ce8fe4b094b8c37dec0177726/c0255/Mosquitto_02.png",
              "alt": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "title": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash sudo docker run -ti -p 1883:1883 -p 9001:9001 --mount source=/opt/mosquitto/config,target=/mosquitto/config --mount source=/opt/mosquitto/data,target=/mosquitto/data --mount source=/opt/mosquitto/log,target=/mosquitto/log eclipse-mosquitto ``` */}
    <p>{` This Docker command runs the `}<a parentName="p" {...{
        "href": "https://hub.docker.com/_/eclipse-mosquitto"
      }}>{`Eclipse Mosquitto`}</a>{` image and mounts all three directories that we just created. For our cameras we only need to expose the port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1883`}</code>{`. Port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`9001`}</code>{` is used for web socket connections - if you are not planning to use software that connect through web sockets, you can remove `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-p 9001:9001`}</code>{` from the command.`}</p>
    <h3 {...{
      "id": "user-authentication",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#user-authentication",
        "aria-label": "user authentication permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`User Authentication`}</h3>
    <p>{`We now want to add a user authentication to our broker, so that only devices that know the login are able to connect. For this we can use a tool that is provided by Mosquitto. Lets start by logging into the docker container that runs Mosquitto. Run the following command to find out the name of the container:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` docker `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{` -a`}</code></pre></div>
    <p>{`As you can see in the screenshot below, Docker decided to call my container `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nostalgic_williams`}</code>{` - yours will be different. To enter the container run the following command - just substitute the container name with yours:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` docker `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -ti nostalgic_williams /bin/sh`}</code></pre></div>
    <p>{`To start the Mosquitto password tool `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mosquitto_pass`}</code>{` run the following command - `}<strong parentName="p">{`note`}</strong>{` that the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin`}</code>{` in the end of the command is the user that is going to be generated. You can choose your own username here and add a password when prompted:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`mosquitto_passwd -c /mosquitto/config/passwd.txt admin`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{` that the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`passwd.txt`}</code>{` file is going to be saved in the directory `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`/mosquitto/config`}</code>{` that we mounted into the container from our host system in the beginning. This means that the user you create here will be persisted if you restart the docker container.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c2a9c920b24e54ab1f63a1c01db46928/c61d0/Mosquitto_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtUlEQVQoz32SW4/aMBCFHUIgCQEWWvWhUtWHfemSe2J8ya1Q9f//pVPN2LCrVurDyM6x/fnMicVx+I1ULtg0A8Tlik0zIrv+xLYZIXLFlfYzkn7meVBo3kfjrl+wbUek3cRaUiqI73LBUS5Y1/a5Me4m7NWNwUk3OaAfM0najLgdkfQLDrXFWzfgW6HwmkuI1+uCk1wQNQOCXGFdWXYX1e6bLiD3cTvxSEXrYWm4m01tkdQWUWmQ1QYiVTfE/cwHCEYjwcLKuPZyhVVpeG1VaqwKzTDSQ69TR2E9YNdYiIO6Ie1mzoIg7lbnhF0Wmg/RnGAEjWqLoHDd0F5qf/0AZnLhwOkW3uBBNKdMqegi0ghEOtUjHtLIxBP4ou/Y+dAjn5/L0B2i1pw+sBvSH2sM892ElXXAmH4IWW5GrCrLWQSUUamfDkWhWXO6QUDuP2S49cB9N0J8ya84VQbnUnO9FArn0uCT1z7X1q1VBoeLxP6tR/ajh7io9wtzN48rA/G1n3HSdxz7GYdu4ieQNgN/79oRWTtiT++ynZBwWwPOF/kOehQ9bAaaX8jU/Z8f8b9ihx7yN/APF546UcVIhZ8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c2a9c920b24e54ab1f63a1c01db46928/e4706/Mosquitto_03.avif 230w", "/en/static/c2a9c920b24e54ab1f63a1c01db46928/d1af7/Mosquitto_03.avif 460w", "/en/static/c2a9c920b24e54ab1f63a1c01db46928/7f308/Mosquitto_03.avif 920w", "/en/static/c2a9c920b24e54ab1f63a1c01db46928/2c261/Mosquitto_03.avif 1145w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c2a9c920b24e54ab1f63a1c01db46928/a0b58/Mosquitto_03.webp 230w", "/en/static/c2a9c920b24e54ab1f63a1c01db46928/bc10c/Mosquitto_03.webp 460w", "/en/static/c2a9c920b24e54ab1f63a1c01db46928/966d8/Mosquitto_03.webp 920w", "/en/static/c2a9c920b24e54ab1f63a1c01db46928/a8d53/Mosquitto_03.webp 1145w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c2a9c920b24e54ab1f63a1c01db46928/81c8e/Mosquitto_03.png 230w", "/en/static/c2a9c920b24e54ab1f63a1c01db46928/08a84/Mosquitto_03.png 460w", "/en/static/c2a9c920b24e54ab1f63a1c01db46928/c0255/Mosquitto_03.png 920w", "/en/static/c2a9c920b24e54ab1f63a1c01db46928/c61d0/Mosquitto_03.png 1145w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c2a9c920b24e54ab1f63a1c01db46928/c0255/Mosquitto_03.png",
              "alt": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "title": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We now have to add the generated password file to our Mosquitto configuration file. While you are still inside the docker container, use:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`vim`}</span>{` /mosquitto/config/mosquitto.conf`}</code></pre></div>
    <p>{`Or when you already left the container, run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo nano /opt/mosquitto/config/mosquitto.conf`}</code>{` to edit the configuration file directly on your host system.`}</p>
    <p>{`We now have to uncomment the two following lines and add the path to our password file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`allow_anonymous false
password_file /mosquitto/config/passwd.txt`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/687cfdc876ac227bafd3275d9368425c/121b3/Mosquitto_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABUElEQVQoz42S2U7DMBBF8zeASJvEjvclcdKW8gYPILEI/v8rLvIkgSJI4eFoPLbm6o5nik1jwGWAMD22zKCsJK42bIbPMFyW/6OoRYB0I7juwHWP1iRoP0L7HaHcCGmHKQ87etsyi+tKoqzVDwohI1wuND2kimCtA5OBqFuPhs5xvououKXCLPgbxb4xeGAGd43BY61gK4mL/LgVq0VnBZkIEMKjFR5idsZUPHHpsGk0sQidxh8tV3NbjYzf21MRXHVohCfRJS7C5ZpgLvL9kbDxANfdIKRbijk3Yfd5b8KeYsXdusPsaio4wMT95Kr19PmZ7KqaHeacVutcy7lV6QYir8y0Ov2U654E87TzNywTPiuY/8zYBOMGaJNohawfYd0AZQfaQeVHEpc2UZ5drgoeucO7SXhSEc+qw4vu8WoT3kzCPXeo88Ba/zWMPxx+ANezZUeqai0dAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/687cfdc876ac227bafd3275d9368425c/e4706/Mosquitto_04.avif 230w", "/en/static/687cfdc876ac227bafd3275d9368425c/d1af7/Mosquitto_04.avif 460w", "/en/static/687cfdc876ac227bafd3275d9368425c/7f308/Mosquitto_04.avif 920w", "/en/static/687cfdc876ac227bafd3275d9368425c/07541/Mosquitto_04.avif 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/687cfdc876ac227bafd3275d9368425c/a0b58/Mosquitto_04.webp 230w", "/en/static/687cfdc876ac227bafd3275d9368425c/bc10c/Mosquitto_04.webp 460w", "/en/static/687cfdc876ac227bafd3275d9368425c/966d8/Mosquitto_04.webp 920w", "/en/static/687cfdc876ac227bafd3275d9368425c/893d8/Mosquitto_04.webp 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/687cfdc876ac227bafd3275d9368425c/81c8e/Mosquitto_04.png 230w", "/en/static/687cfdc876ac227bafd3275d9368425c/08a84/Mosquitto_04.png 460w", "/en/static/687cfdc876ac227bafd3275d9368425c/c0255/Mosquitto_04.png 920w", "/en/static/687cfdc876ac227bafd3275d9368425c/121b3/Mosquitto_04.png 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/687cfdc876ac227bafd3275d9368425c/c0255/Mosquitto_04.png",
              "alt": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "title": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now stop the container and restart it the way you started it earlier. All configuration should be persisted. And we can now try to connect our cameras to the MQTT service.`}</p>
    <h2 {...{
      "id": "connecting-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connecting-cameras",
        "aria-label": "connecting cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting Cameras`}</h2>
    <p>{`We can now configure our INSTAR Full HD cameras to connect to our MQTT broker. Start by opening the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT Broker Configuration`}</a>{` in your camera's web user interface:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/abd275de885550ce0e8cbfb36b855316/d56b5/Mosquitto_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACF0lEQVQoz3XSS28SURjGcb6AC28xxAhiarkMFAOEpqK9CMPMdK5nhmGCA71oKC1Fq22Gpt2YqCtj/AAu3NqFid/BL/Y3Q22NSV08OSdn8cvzvjmJUqmEJElIxSL5XJZCuYodfcM8/YEafUednv1NdDZ767z7ib7/hfT9DNVKhVqtRjKZJJPJkCgUClwkl80iFUt4/SGD0SH+5pjOYPc8G3t0Nsb4gxHd4RSxdYBiGyjCxvA7hNtbrJsmibjdJZjLIUkFbFMn8F18T+B7DsI2sXQNY11F12R0w8L2A8LRS7YPJgyP3jL98J7BcHjeMEbz+fwfUMIRgl7vOd1uF8/zcF2X1bU16ouL1OuL1KoVtHWN8XHE5GTKbnTI6DjCCAISMRSj/4COQ6/Xo9PpIISYgZZloes6RhzbxXA8TN+lZVs8VVXEZh9FOFeDMRKG4Qy6ADVNRZZbtJ6t0mw20S2HZUUm9yhL7XEOOwww44ZXjWzb9uW45yMLWopOY7VFU3NRRR9Z0ykvNWiqC0zGaXbe7GF2e1c3jMEgCPB9H89zsRzBx12dr6/XGESfcT79otXd4fqNa6QepGisVGZtF2rV/zeMwXiHceKRX+2PmUZHTPovOA5HCFXnZvIWt5N3KJTLVJfqSOXi3394Acb3eEeKotBut2en0pZp+mNW+qdsyTonK8uYjSfcTadIpe8xNz/HfD7Lw+w8vwE6+WyKl9rQcQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abd275de885550ce0e8cbfb36b855316/e4706/Mosquitto_05.avif 230w", "/en/static/abd275de885550ce0e8cbfb36b855316/d1af7/Mosquitto_05.avif 460w", "/en/static/abd275de885550ce0e8cbfb36b855316/7f308/Mosquitto_05.avif 920w", "/en/static/abd275de885550ce0e8cbfb36b855316/eb15d/Mosquitto_05.avif 1215w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/abd275de885550ce0e8cbfb36b855316/a0b58/Mosquitto_05.webp 230w", "/en/static/abd275de885550ce0e8cbfb36b855316/bc10c/Mosquitto_05.webp 460w", "/en/static/abd275de885550ce0e8cbfb36b855316/966d8/Mosquitto_05.webp 920w", "/en/static/abd275de885550ce0e8cbfb36b855316/34ce3/Mosquitto_05.webp 1215w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abd275de885550ce0e8cbfb36b855316/81c8e/Mosquitto_05.png 230w", "/en/static/abd275de885550ce0e8cbfb36b855316/08a84/Mosquitto_05.png 460w", "/en/static/abd275de885550ce0e8cbfb36b855316/c0255/Mosquitto_05.png 920w", "/en/static/abd275de885550ce0e8cbfb36b855316/d56b5/Mosquitto_05.png 1215w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/abd275de885550ce0e8cbfb36b855316/c0255/Mosquitto_05.png",
              "alt": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "title": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The IP address of my LINUX host system is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.11`}</code>{`, we left the MQTT ports that Mosquitto uses at their default values and I added the user login `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin/instar`}</code>{`. Change those settings according to your setup.`}</p>
    <h2 {...{
      "id": "node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red",
        "aria-label": "node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED`}</h2>
    <p>{`You can now `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/#software"
      }}>{`connect every compatible MQTT Software`}</a>{` to the Mosquitto Broker to control every connected camera. In the following we will show how to connect `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
      }}>{`Node-RED`}</a>{` as an example.`}</p>
    <p>{`You can download the Node-RED flow that I am going to use from here: `}<a parentName="p" {...{
        "href": "/en/Node-RED_Flows/MQTT_Mosquitto_Node-RED.json"
      }}>{`Download Flow`}</a>{`.`}</p>
    <p>{`We already used this Node-RED flow in our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/HiveMQ/#node-red"
      }}>{`HiveMQ Tutorial`}</a>{`. The only thing that we have to change now to make it work with Mosquitto is to add the MQTT Broker configuration to Node-RED. Do this by adding a new MQTT Broker to every MQTT node inside the flow:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8a2ba564a4941fc849c873b2cdab50c7/60b8f/Mosquitto_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACIklEQVQoz42S608aQRTF+f99xFBNbPSDkVZpfe9CWfbB7rqwsLMVwSj6tU2qEBtLEXnjr5mxS/qxNzm5Mze5Z849d1Lr79JsbKyTTqdZWVlhaWmJ5eVlVldXWVtbU+etrS12d3fZ3t5mc/O9yplMRtWy2SyarnOwt8+HnR1SkYgR4oKLep3m1RXNZpOb1g2tVou721uur695+vXEcDhkMpkwHo+ZTqfM5/M3zGbMgW/HOt91g5RWLPDx9BjP9zk3LSwtj5s38F2PnOfjngd0Hh4YjkaKMCGbzWYLyPtoNOL5+ZlUwT7jUMtQCQNKX0zyBxrGUQ6raLNfNCk6Lo8PbcaTsVIpGweDgcpSrTzLunxIIuUYJvmjU8JyBc9yMc4MrJyF63icOA6O5/Oz02HwlyxRIokkut0u/X5fkUukLNdDM0zCapXwPMB3fMpemUpQwQoCgjCkfX+vVPR6PUUwmUyR8fr6qkaWWYZ8IFUNK5SDABFF2DmLs08a+medomGzZ1oU7BKdH28eSiUj5eV4MaYkkx7KUAqr1Rq1miCOBU7BpHBSwNJNHKvEoWlhex6P7Tb9lxelUKqQy5FIiBKFysOqbxDax4haha9xjIgEsYgVIiEQQtD73V00Js1J/FtThIGXxTbS1GoujcYVl43LBZqNBvV6XS3hf0IRlgMNt5ShGvrI8cOwQhiGRFGEiGOVO52O8k+OLJF4KX1Mvo1cjsx/AEiKCiWjtBDkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a2ba564a4941fc849c873b2cdab50c7/e4706/Mosquitto_06.avif 230w", "/en/static/8a2ba564a4941fc849c873b2cdab50c7/d1af7/Mosquitto_06.avif 460w", "/en/static/8a2ba564a4941fc849c873b2cdab50c7/7f308/Mosquitto_06.avif 920w", "/en/static/8a2ba564a4941fc849c873b2cdab50c7/dcb15/Mosquitto_06.avif 1217w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8a2ba564a4941fc849c873b2cdab50c7/a0b58/Mosquitto_06.webp 230w", "/en/static/8a2ba564a4941fc849c873b2cdab50c7/bc10c/Mosquitto_06.webp 460w", "/en/static/8a2ba564a4941fc849c873b2cdab50c7/966d8/Mosquitto_06.webp 920w", "/en/static/8a2ba564a4941fc849c873b2cdab50c7/ffb96/Mosquitto_06.webp 1217w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a2ba564a4941fc849c873b2cdab50c7/81c8e/Mosquitto_06.png 230w", "/en/static/8a2ba564a4941fc849c873b2cdab50c7/08a84/Mosquitto_06.png 460w", "/en/static/8a2ba564a4941fc849c873b2cdab50c7/c0255/Mosquitto_06.png 920w", "/en/static/8a2ba564a4941fc849c873b2cdab50c7/60b8f/Mosquitto_06.png 1217w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8a2ba564a4941fc849c873b2cdab50c7/c0255/Mosquitto_06.png",
              "alt": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "title": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`On the `}<strong parentName="p">{`Connection`}</strong>{` tab add the server (broker) address and port. And on the `}<strong parentName="p">{`Security`}</strong>{` tab add the Mosquitto user that you created - in my case `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin/instar`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e6c094d852864171207844420eaaa825/60b8f/Mosquitto_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACFElEQVQoz42S3WvaUBjG8z+3pVAQob1ae7lRWG8Ho6x1bednzJfG5MSo00bb65Wx1SL9mBY1VuNvnDOU3mzshYfnPeHkx5snr5ZOpUin0+zs7LC5ucnGxobyra0ttre31TmVSrG7u6tc3pO+t7ennu3v73N0dMS7w0PeHByghWGDZrNFq/WVdqdDu9Mm6nbp9XpcX11x2ekwGAwYj8c8Pz8zHA6VT+OYOI6ZTqfEizk3FwW+neXQ8mWD02yequviOxUc3aBm2QjPw/QFNV9wd9tXkJeXFxaLhdJ8Pl8rWS4Z9e+4v/mOljXO+fjlA56o4eR1CicX6Jkspm5wrJfRLZvBbZ9FkqhppFbgJElUL7UEFoDmlEoUMxkC38PWLfKZAqVzHctwODFMynaFQb/PcDRiNBqtP11NliQqAqnJeMwsjtF0y+EsX0IEAUHNw7Vd/KqP8ARWzcMTgv7PH0wmEyUJWi6XCia16mXNZjM036vhViuEYYhdtMid5ihkChhli0+mRcm0eRzcqxdeA6S/Bq+B9XpIEDZpNJuYeZ3sSY7i5yJGyVQZliybp/s/QAn4V8lctcA1CZwCzTCgLgS+6xP4sg9whVBR/Hp6XAP/JlkyDs2vnuLo7wnrLlG3RxRFSpfRJd0oot1uq5/xP6UmdCvHGPpbhLCp1xsIOZUQNBoNWq2W8oeHB3V5tSJSr/dwdZaL/hvJAQSg+P7LYAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6c094d852864171207844420eaaa825/e4706/Mosquitto_07.avif 230w", "/en/static/e6c094d852864171207844420eaaa825/d1af7/Mosquitto_07.avif 460w", "/en/static/e6c094d852864171207844420eaaa825/7f308/Mosquitto_07.avif 920w", "/en/static/e6c094d852864171207844420eaaa825/dcb15/Mosquitto_07.avif 1217w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e6c094d852864171207844420eaaa825/a0b58/Mosquitto_07.webp 230w", "/en/static/e6c094d852864171207844420eaaa825/bc10c/Mosquitto_07.webp 460w", "/en/static/e6c094d852864171207844420eaaa825/966d8/Mosquitto_07.webp 920w", "/en/static/e6c094d852864171207844420eaaa825/ffb96/Mosquitto_07.webp 1217w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6c094d852864171207844420eaaa825/81c8e/Mosquitto_07.png 230w", "/en/static/e6c094d852864171207844420eaaa825/08a84/Mosquitto_07.png 460w", "/en/static/e6c094d852864171207844420eaaa825/c0255/Mosquitto_07.png 920w", "/en/static/e6c094d852864171207844420eaaa825/60b8f/Mosquitto_07.png 1217w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e6c094d852864171207844420eaaa825/c0255/Mosquitto_07.png",
              "alt": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "title": "Mosquitto Broker for INSTAR MQTT Full HD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you deployed those changes in Node-RED, you should be able to see the same switches and buttons to control your cameras alarm areas on the Node-RED dashboard that we created `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/HiveMQ/#node-red"
      }}>{`HiveMQ Tutorial`}</a>{` (note that you have to replace the cameras MQTT IDs/MAC Adresses with those of your own cameras). For more details, please continue reading our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
      }}>{`Node-RED Tutorial`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      